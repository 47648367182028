<template>
  <div>
    <b-overlay
      :show="loadingEvents"
      rounded="sm"
    >
      <b-row>
        <!-- Search -->
        <b-col
          cols="12"
          class="mt-2 mb-4"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchValue"
              class="d-inline-block mr-1"
              placeholder="Filtrar..."
              @keyup="filtro($event, 'title', filterGlobal, filterBySpeaker)"
            />
          </div>
        </b-col>
      </b-row>

      <div class="row">
        <b-col
          v-for="(event, eventIndex) in congressesActivities"
          :key="eventIndex"
          cols="12"
          md="4"
          sm="12"
        >
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <div
              class="bg-light-primary rounded-top text-center"
              style="background-color: #D1E9FD !important;"
            >
              <b-img
                :src="require('@/assets/images/illustration/thumb_padrao_510x170.svg')"
                alt="Meeting Pic"
                height="170"
                width="100%"
              />
            </div>
            <b-card-body>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-center">
                <div class="meetup-day">
                  <h6 class="mb-0">
                    {{ (moment(event.date, "YYYYMMDD").format('ddd')).toUpperCase() }}
                  </h6>
                  <h3 class="mb-0">
                    {{ moment(event.date, "YYYYMMDD").format('D') }}
                  </h3>
                </div>
                <div class="my-auto">
                  <b-card-title class="mb-25">
                    {{ event.title }}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    Sala {{ event.room }}
                  </b-card-text>
                </div>
              </div>
              <!--/ metting header -->

              <!-- media -->
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ moment(event.date, "YYYYMMDD").format('LL') }}
                  </h6>
                  <small> Hórario: {{ moment(event.start_time, 'h:mm').format('LT') }} - {{ moment(event.end_time, 'h:mm').format('LT') }}</small>
                </b-media-body>
              </b-media>

              <!-- avatar group -->
              <b-avatar-group
                class="mt-2 pt-50"
                size="33"
              >
                <b-avatar
                  v-for="speaker in event.speakers"
                  :key="speaker.id"
                  v-b-tooltip.hover.bottom="speaker.name_badge"
                  :src="`https://apisnno.ovnicloud.page/storage/image/${speaker.photo}`"
                  class="pull-up"
                />
              </b-avatar-group>
              <br>
              <b-button
                v-b-modal="'modal-'+event.id"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                Detalhes
              </b-button>
              <b-button
                v-if="
                  isOnTime(
                    event.date,
                    event.start_time,
                    event.end_time
                  )
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                style="margin: 5px"
                variant="outline-success"
                :href="'/congress/'+form.slug+'/'+event.slug"
              >
                Assistir
              </b-button>
              <b-modal
                :id="`agendar-${event.id}`"
                title="Agendar"
                hide-footer
              >
                <b-button
                  class="mt-3"
                  variant="danger"
                  block
                  @click="$bvModal.hide('agendar-'+event.id)"
                >
                  Fechar
                </b-button>
              </b-modal>

              <b-modal
                :id="'modal-'+event.id"
                :title="event.title"
                hide-footer
              >
                <div>
                  <p v-if="event.cordinator != null">
                    <b>
                      Moderador:
                    </b>
                  </p>
                  <p>
                    {{ event.cordinator }}
                  </p>
                </div>
                <div
                  v-for="blocks in event.blocks"
                  :key="blocks.id"
                >
                  <p>
                    <b>
                      {{ blocks.title }}
                    </b>
                  </p>
                  <p
                    v-for="speaker in blocks.events_blocks_speaker"
                    :key="speaker.id"
                  >
                    {{ speaker.name_badge }}
                  </p>
                </div>
                <b-button
                  class="mt-3"
                  variant="danger"
                  block
                  @click="$bvModal.hide('modal-'+event.id)"
                >
                  Fechar
                </b-button>
              </b-modal>
            </b-card-body>
          </b-card>
        </b-col>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import BCardCode from '@core/components/b-card-code'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { codeAccordionDefault } from './collapse/code'
// NOTA: a função de listar os eventos pela data atual chamara o acesso do usuario
// ROTA: events/per/date/now
import CongressServices from './congressServices'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCol,
    BImg,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const todaysDate = new Date()
  },
  data() {
    return {
      loading: true,
      loadingEvents: true,
      isLive: false,
      ordVerify: [],
      events: [],
      paid: null,
      codeAccordionDefault,
      form: {
        slug: this.$route.params.slug
      },
      congressesActivities: null,
      searchValue: '',
      formNotification: {
        event_id: null
      },
      avatars: [
        { avatar: "require('@/assets/images/portrait/small/avatar-s-20.jpg')", fullName: 'Jenny Looper' },
      ],
    }
  },
  computed: {
    userEvents() {
      return this.$store.state.congress.userEvents
    },
    order() {
      return this.$store.state.payment.order
    },
    user() {
      return this.$store.state.auth.sessionInfo.user
    },
    slug() {
      return this.$route.params.slug
    },
    speakerEvent() {
      return this.$store.state.congress.speakerEvents
    },
  },
  watch: {
    congressesActivities() {},
    userEvents() {},
    async ordVerify() {
      if (
        this.order.some(e => e.transaction_status === 3 || e.transaction_status === 3) ||
        this.user.role === 'ADMIN' ||
        this.user.role === 'MASTER' ||
        this.user.role === 'SPONSOR' ||
        this.user.role === 'SPEAKER'
      ) {
        this.paid = true
      }
    },
    paid() {
      if (this.paid) {
        this.loading = false
      } else {
        this.$router.back()
      }
    },
    slug() {
      console.log(`mudou para${this.slug}`)
      this.ActionLoadCurrentActivities({ slug: this.slug })
    }
  },
  created() {
    this.moment = moment
    moment.locale('pt-br')
    CongressServices().getEventAll({ congress_slug: this.$route.params.slug }).then(res => {
      this.congressesActivities = res.data.response.events
      this.events = res.data.response.events
      this.loadingEvents = false
      console.log(this.events
      )
    })
  },
  mounted() {
    this.LoadPreviousActivities().then(() => {
      this.ActionCongressAccess(this.form)
    })
    this.ActionGetEventBySpeaker()
    this.ActionGetUSerEvents()
    this.ordVerify = this.order
  },
  methods: {
    ...mapActions('congress', [
      'ActionLoadPreviousActivities',
      'ActionCongressAccess',
      'ActionRegisterUserEvent',
      'ActionUnregisterUserEvent',
      'ActionGetUSerEvents',
      'ActionGetEventBySpeaker'
    ]),
    async LoadPreviousActivities() {
      console.log('teste')
      await this.ActionLoadPreviousActivities(this.form)
    },
    URLGenerateGoogleCalendar(p1, p2, p3, p4, p5, p6, p7, p8, p9, p10) {
      const url =
        `https://www.google.com/calendar/event?action=TEMPLATE&text=${
          p1
        }&dates=${
          p2
        }${p3
        }${p4
        }T${
          p5
        }0000Z/${
          p6
        }${p7
        }${p8
        }T${
          p9
        }0000Z&details=${
          p10
        }&location=`
      return url
    },
    ICALGenerate(event) {
      const now = new Date()
      const start = new Date(`${event.date} ${event.start_time}:00`)
      const end = new Date(`${event.date} ${event.end_time}:00`)
      const fileName = `Congresso - ${event.title}`
      const language = 'pt-br'
      const subject = `Congresso - ${event.title}`
      const description = `Congresso - ${event.title}`
      const location = 'http://snno.ovnicloud.page/'
      const url = 'http://snno.ovnicloud.page/'
      const organizer = null
      this.$ics.addEvent(language, subject, description, location, start, end, url, organizer)
      this.$ics.calendar()
      this.$ics.download(fileName)
      this.$ics.removeAllEvents()
    },
    async ActiveNotification(id) {
      this.formNotification.event_id = id
      await this.ActionRegisterUserEvent(this.formNotification)
      await this.ActionGetUSerEvents()
    },
    async DeactiveNotification(id) {
      await this.ActionUnregisterUserEvent({
        event_id: id
      })
      await this.ActionGetUSerEvents()
    },
    isOnTime(date, timeStart, timeEnd) {
      const d = date.split('-')
      const dateNow = new Date()
      let subtrac = new Date(`${d[1]}/${d[2]}/${d[0]} ${timeStart}`)
      subtrac = subtrac.setMinutes(subtrac.getMinutes() - 20)
      const dateStart = new Date(subtrac)

      if (dateNow >= dateStart) {
        this.isLive = true
        return true
      }
      return false
    },
    filtro(e, propriedadePesquisa, filterGlobal, filterBySpeaker) {
      if (e.target.value.trim() !== '') {
        const lista = this.events
        const result = filterGlobal(e, lista, propriedadePesquisa)
        if (result.length > 1) {
          this.congressesActivities = result
          console.log(result)
        } else {
          const resultSpeaker = filterBySpeaker(e, lista)
          this.congressesActivities = resultSpeaker
          console.log(resultSpeaker)
        }
      } else {
        this.congressesActivities = this.events
      }
    },
  }
}
</script>

<style>

</style>
